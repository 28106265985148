import React from 'react'
import { Link } from 'gatsby'

import SEO from '../components/SEO'
import HeroHeader from '../components/HeroHeader'
import { Email } from '../components/ContactOptions'

const title = 'Datenschutz | Stadlwirt'

const PrivacyPage = () => {
  return (
    <>
      <SEO title={title} />
      <HeroHeader title="Datenschutz" />
      <div className="container py-10">
        <h2 className="text-xl font-semibold">Datenschutzerklärung</h2>
        <hr className="my-2" />
        <p>
          Der Stadlwirt legt großen Wert auf den Schutz Ihrer personenbezogener
          Daten. Soweit die Nutzung unserer Website nicht ohne Angabe
          personenbezogener Daten möglich ist, erfolgt eine Verwendung Ihrer
          personenbezogenen Daten (wie bspw. Name, Anschrift, E-Mail-Adresse)
          stets freiwillig und nur mit Ihrer Zustimmung. Dies gilt auch für eine
          Weitergabe Ihrer personenbezogenen Daten an Dritte.
        </p>
        <p>
          Die uns von Ihnen im Rahmen von &ldquo;Anfragen&quot; bekanntgegebene
          E-Mail-Adresse und Ihre Nachricht werden von uns ausschließlich zum
          Zweck der Bearbeitung Ihrer Anfrage verwendet.
        </p>
        <p>
          Wir haben entsprechend den gesetzlichen Vorgaben für die elektronische
          Kommunikation und des Datenschutzes die vorgeschriebenen technischen
          und organisatorischen Maßnahmen getroffen.
        </p>
        <p>
          Sie haben jederzeit das Recht auf Auskunft, Richtigstellung oder
          Löschung Ihrer personenbezogenen Daten. Zum Zwecke des technischen
          Betriebes der Webseite werden IP-Adresse, Datum, Uhrzeit und
          betrachtete Seiten beim Besuch der Webseite erhoben. Diese dienen
          ausschließlich der Nachvollziehbarkeit im Fehlerfall und der
          Fehlerbehebung aus technischer Sicht. Diese Daten werden nicht an
          Dritte weiter gegeben.
        </p>
        <br />

        <h2 className="text-xl font-semibold">Google Analytics</h2>
        <hr className="my-2" />
        <p>
          Unsere Website (
          <Link to="/" className="golden">
            www.stadlwirt.at
          </Link>
          ) benutzt Google Analytics, einschließlich der Funktionen von
          Universal Analytics, einen Webanalysedienst der Google Inc.
          (&ldquo;Google&quot;).
          <br />
          <br />
          <strong>
            Wir verwenden dabei keine Werbefunktionen und kein
            geräteübergreifendes Tracking Ihrer Daten mittels User-ID.
          </strong>
        </p>
        <p>
          Google Analytics verwendet sogenannte &ldquo;Cookies&quot;,
          Textdateien, die auf Ihrem Computer gespeichert werden und die eine
          Analyse der Benutzung der Website durch Sie ermöglichen. Die durch das
          Cookie erzeugten Informationen über Ihre Benutzung dieser Website
          werden in der Regel an einen Server von Google in den USA übertragen
          und dort gespeichert.
        </p>
        <br />
        <p>
          <strong>IP-Anonymisierung (IP-Masking):</strong> Aufgrund der nicht
          abschließend geklärten Rechtslage zur Verwendung von Analysetools mit
          vollständigen IP-Adressen, wird auf unserer Website Google Analytics
          mit der Erweiterung &ldquo;_anonymizeIp()&quot; verwendet. Ihre
          IP-Adresse wird daher von Google vor der weiteren Verwendung noch
          innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen
          Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum
          gekürzt und somit nur mehr anonymisiert verwendet. Nur in
          Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in
          den USA übertragen und dort gekürzt.
        </p>
        <p>
          In unserem Auftrag wird Google diese Informationen benutzen, um Ihre
          Nutzung der Website auszuwerten, um Reports über die
          Websiteaktivitäten zusammenzustellen und um weitere mit der
          Websitenutzung und der Internetnutzung verbundene Dienstleistungen uns
          gegenüber zu erbringen.
        </p>
        <p>
          Die im Rahmen von Google Analytics von Ihrem Browser übermittelte
          (verkürzte) IP-Adresse wird nicht mit anderen Daten von Google
          zusammengeführt. Sie können die Speicherung der Cookies durch eine
          entsprechende Einstellung Ihrer Browser-Software verhindern; wir
          weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls
          nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen
          können. Sie können darüber hinaus die Erfassung der durch das Cookie
          erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl.
          Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch
          Google verhindern, indem Sie das unter dem folgenden Link (
          <a
            className="golden"
            rel="noopener noreferrer"
            href="http://tools.google.com/dlpage/gaoptout?hl=de"
            target="_blank"
          >
            http://tools.google.com/dlpage/gaoptout?hl=de
          </a>
          ) verfügbare Browser-Plugin herunterladen und installieren.
        </p>
        <p>
          Nähere Informationen zu Nutzungsbedingungen und Datenschutz von Google
          Analytics finden Sie unter{' '}
          <a
            className="golden"
            rel="noopener noreferrer"
            href="https://www.google.de/intl/de/policies/"
            target="_blank"
          >
            https://www.google.de/intl/de/policies/
          </a>
          .
        </p>
        <br />

        <h2 className="text-xl font-semibold">Cookies</h2>
        <hr className="my-2" />
        <p>
          Nachfolgend finden Sie eine Auflistung der von uns gesetzten
          zustimmungspflichtigen Cookies.
        </p>
        <br />
        <table className="border-collapse">
          <thead className="text-left">
            <tr>
              <th className="border border-gray-400 px-4 py-2 text-gray-800">
                Name des Cookies
              </th>
              <th className="border border-gray-400 px-4 py-2 text-gray-800">
                Funktion
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border border-gray-400 px-4 py-2 text-gray-800">
                <p>_ga Speicherdauer (2 Jahre ab dem Aufruf)</p>
                <p>_gid Speicherdauer (24 Stunden ab dem Aufruf)</p>
                <p>_gat Speicherdauer (1 Minute ab dem Aufruf)</p>
              </td>
              <td className="border border-gray-400 px-4 py-2 text-gray-800">
                <p>Google Analytics</p>
                <span>
                  Diese Cookies werden verwendet zur Analyse der Anzahl der
                  Website-Besucher und welche Informationen diese dort gesucht
                  und welche Seiten wie oft aufgerufen wurden.
                  <br />
                  Klicken Sie{' '}
                  <a
                    className="golden"
                    href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    hier
                  </a>{' '}
                  um weitere Informationen zu Google-Analytics-spezifischen
                  Cookies zu erhalten
                </span>
              </td>
            </tr>
            <tr>
              <td className="border border-gray-400 px-4 py-2 text-gray-800">
                <p>user-has-accepted-cookies (1 Jahr ab dem Aufruf)</p>
              </td>
              <td className="border border-gray-400 px-4 py-2 text-gray-800">
                <p>
                  Dieser Cookie wird verwendet um zu überprüfen, ob Sie Cookies
                  von unserer Website akzeptieren.
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <p>
          Eine Nutzung unserer Angebote ist auch ohne Cookies möglich, abgesehen
          vom Speichern der Webcam Favoriten. Die meisten Browser sind so
          eingestellt, dass sie Cookies automatisch akzeptieren. Sie können das
          Speichern von Cookies jedoch deaktivieren oder Ihren Browser so
          einstellen, dass er Sie benachrichtigt, sobald Cookies gesendet
          werden.
        </p>
        <br />

        <h2 className="text-xl font-semibold">Weitere Informationen</h2>
        <hr className="my-2" />
        <p>
          Für weitere allgemeine Information zu den hier angeführten Themen
          sowie Ihren eingangs erwähnten Rechten auf Auskunft, Richtigstellung
          und Löschung, stehen wir Ihnen gerne unter{' '}
          <Email className="golden" /> zur Verfügung.
        </p>
      </div>
    </>
  )
}

export default PrivacyPage
